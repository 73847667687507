export const portfolioHighlightData = [
    {
      id:1,
      img: "assets/portfolio/p1.png"
    },
    {
      id:2,
      img: "assets/portfolio/p2.jpg"
    },
    {
      id:3,
      img: "assets/portfolio/p3.png"
    },
    {
      id:4,
      img: "assets/portfolio/p4.png"
    },
    {
      id:5,
      img: "assets/portfolio/p5.png"
    },
    {
      id:6,
      img: "assets/portfolio/p6.png"
    },
    {
      id:7,
      img: "assets/portfolio/p1.png"
    },
    {
      id:8,
      img: "assets/portfolio/p2.jpg"
    },
    {
      id:9,
      img: "assets/portfolio/p3.png"
    },
    {
      id:10,
      img: "assets/portfolio/p4.png"
    },
    {
      id:11,
      img: "assets/portfolio/p5.png"
    },
    {
      id:12,
      img: "assets/portfolio/p6.png"
    }
  ];
